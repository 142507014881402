import { StringUtil } from '@shared/string-util';
import {
  Consent,
  ConsentData,
  ConsentType,
  ContactInfo,
  DefaultCase,
} from '@app/states/case/default/default-case.model';
import { FormlyFieldConfig } from '@ngx-formly/core';
import isEqual from 'lodash.isequal';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { CaseHelper } from '../case-helper';

dayjs.extend(customParseFormat);

export class DefaultCaseHelper<CT extends DefaultCase = DefaultCase> extends CaseHelper<CT> {
  public override preProcessCase(caseObject: CT): CT {
    const { customer } = caseObject;

    // Safe the email address the customer was contacted by for later use.
    customer.contactEmail = customer.private?.emailAddress;

    // Set default country code to DE if empty
    if (customer.address) {
      customer.address = {
        ...customer.address,
        countryCode: StringUtil.isEmpty(customer.address?.countryCode) ? 'DE' : customer?.address?.countryCode,
      };
    }

    // Supported states are only NO_COMMENT and ACCEPTED, so DENIED will be converted to NO_COMMENT
    customer.consent = {
      phone: this.preProcessConsentData(customer.consent?.phone),
      email: this.preProcessConsentData(customer.consent?.email),
    };

    return caseObject;
  }

  public override postProcessCase(caseObject: CT, initialCase: CT): CT {
    const { customer: currentCustomer } = caseObject;
    const { customer: initialCustomer } = initialCase;

    // Apply the following rules:
    // 1. Consent will be ACCEPTED when an emailAddress or phonenumber changed AND the customer checked the corresponding DEW checkbox - in all other cases the consent will be set to NO_COMMENT
    // 2. Consent will be NO_COMMENT, when all corresponding emailAddresses or phonenumbers are empty
    // 3. Set the customerConsentUpdateAt only when there are changes to the corresponding emailAddresses or phonenumbers
    if (currentCustomer.consent) {
      // Handle email consent
      const initialEmails = [initialCustomer.private?.emailAddress, initialCustomer.business?.emailAddress];
      const currentEmails = [currentCustomer.private?.emailAddress, currentCustomer.business?.emailAddress];
      const initialEmailConsent = initialCustomer.consent.email.customerConsent;
      const currentEmailConsent = currentCustomer.consent.email.customerConsent;

      const MAIL_CONSENT_HAS_CHANGED =
        !isEqual(initialEmails, currentEmails) || !isEqual(initialEmailConsent, currentEmailConsent);
      const MAIL_CONSENT_IS_ACCEPTED = MAIL_CONSENT_HAS_CHANGED && currentEmailConsent === ConsentType.ACCEPTED;
      const MAILS_ARE_EMPTY = currentEmails.every((email) => StringUtil.isEmpty(email));

      // Handle phone consent
      const initialPhonenumbers = [
        initialCustomer.private?.phoneNumber,
        initialCustomer.private?.mobileNumber,
        initialCustomer.business?.phoneNumber,
        initialCustomer.business?.mobileNumber,
      ];
      const currentPhonenumbers = [
        currentCustomer.private?.phoneNumber,
        currentCustomer.private?.mobileNumber,
        currentCustomer.business?.phoneNumber,
        currentCustomer.business?.mobileNumber,
      ];
      const initialPhoneConsent = initialCustomer.consent.phone.customerConsent;
      const currentPhoneConsent = currentCustomer.consent.phone.customerConsent;

      const PHONE_CONSENT_HAS_CHANGED =
        !isEqual(initialPhonenumbers, currentPhonenumbers) || !isEqual(initialPhoneConsent, currentPhoneConsent);
      const PHONE_CONSENT_IS_ACCEPTED = PHONE_CONSENT_HAS_CHANGED && currentPhoneConsent === ConsentType.ACCEPTED;
      const PHONENUMBERS_ARE_EMPTY = currentPhonenumbers.every((phonenumber) => StringUtil.isEmpty(phonenumber));

      // override customer values
      currentCustomer.consent = {
        phone: {
          ...currentCustomer.consent.phone,
          customerConsent:
            PHONE_CONSENT_IS_ACCEPTED && !PHONENUMBERS_ARE_EMPTY ? ConsentType.ACCEPTED : ConsentType.NO_COMMENT,
          customerConsentUpdateAt: PHONE_CONSENT_HAS_CHANGED ? dayjs().toISOString() : '',
        },
        email: {
          ...currentCustomer.consent.email,
          customerConsent: MAIL_CONSENT_IS_ACCEPTED && !MAILS_ARE_EMPTY ? ConsentType.ACCEPTED : ConsentType.NO_COMMENT,
          customerConsentUpdateAt: MAIL_CONSENT_HAS_CHANGED ? dayjs().toISOString() : '',
        },
      };
    }

    return caseObject;
  }

  public resetConsentFn(formState: any, consent: ConsentType, field: FormlyFieldConfig) {
    if (field.hide === false) {
      return field.defaultValue;
    }
    return consent;
  }

  public requiredEmailFn(formState: any, model: CT) {
    const initialCustomer = formState.initialModel.customer;
    const currentCustomer = model.customer;

    return (
      (this.hasPhoneNumberChanged(initialCustomer.private?.mobileNumber, currentCustomer.private?.mobileNumber) ||
        this.hasPhoneNumberChanged(initialCustomer.private?.phoneNumber, currentCustomer.private?.phoneNumber) ||
        this.hasPhoneNumberChanged(initialCustomer.business?.mobileNumber, currentCustomer.business?.mobileNumber) ||
        this.hasPhoneNumberChanged(initialCustomer.business?.phoneNumber, currentCustomer.business?.phoneNumber) ||
        initialCustomer.consent.phone.customerConsent === ConsentType.NO_COMMENT) &&
      currentCustomer.consent.phone.customerConsent === ConsentType.ACCEPTED &&
      StringUtil.isEmpty(currentCustomer.private?.emailAddress)
    );
  }

  public checkEmailConsentFn(formState: any, model: DefaultCase) {
    const initialPrivateContact = formState.initialModel.customer.private as ContactInfo;
    const initialConsent = formState.initialModel.customer.consent as Consent;
    const currentPrivateContact = model.customer.private as ContactInfo;
    const currentBusinessContact = model.customer.business as ContactInfo;

    return (
      (StringUtil.isEmpty(currentPrivateContact.emailAddress) &&
        StringUtil.isEmpty(currentBusinessContact.emailAddress)) ||
      (initialConsent.email.customerConsent === ConsentType.ACCEPTED &&
        initialPrivateContact.emailAddress === currentPrivateContact.emailAddress)
    );
  }

  public hasConsentedMailDew(formstate: any, model: CT) {
    return model.customer.consent.email.customerConsent === ConsentType.ACCEPTED;
  }

  public checkPhoneConsentFn(formState: any, model: CT) {
    const initialPrivateContact = formState.initialModel.customer.private as ContactInfo;
    const initialBusinessContact = formState.initialModel.customer.business as ContactInfo;
    const initialConsent = formState.initialModel.customer.consent as Consent;

    const currentPrivateContact = model.customer.private as ContactInfo;
    const currentBusinessContact = model.customer.business as ContactInfo;

    return (
      (StringUtil.isEmpty(currentPrivateContact.phoneNumber) &&
        StringUtil.isEmpty(currentPrivateContact.mobileNumber) &&
        StringUtil.isEmpty(currentBusinessContact.phoneNumber) &&
        StringUtil.isEmpty(currentBusinessContact.mobileNumber)) ||
      (initialConsent.phone.customerConsent === ConsentType.ACCEPTED &&
        (initialPrivateContact.phoneNumber === currentPrivateContact.phoneNumber ||
          StringUtil.isEmpty(currentPrivateContact.phoneNumber)) &&
        (initialPrivateContact.mobileNumber === currentPrivateContact.mobileNumber ||
          StringUtil.isEmpty(currentPrivateContact.mobileNumber)) &&
        (initialBusinessContact.phoneNumber === currentBusinessContact.phoneNumber ||
          StringUtil.isEmpty(currentBusinessContact.phoneNumber)) &&
        (initialBusinessContact.mobileNumber === currentBusinessContact.mobileNumber ||
          StringUtil.isEmpty(currentBusinessContact.mobileNumber)))
    );
  }

  public hasEmailFn(formstate: any, model: CT) {
    const { customer } = model;

    return !StringUtil.isEmpty(customer.private?.emailAddress) || !StringUtil.isEmpty(customer.business?.emailAddress);
  }

  protected preProcessConsentData(consentData: ConsentData | undefined): ConsentData {
    if (!consentData) {
      return { customerConsent: ConsentType.NO_COMMENT };
    }
    return consentData.customerConsent === ConsentType.DENIED
      ? { ...consentData, customerConsent: ConsentType.NO_COMMENT }
      : { ...consentData };
  }

  protected hasPhoneNumberChanged(originalPhoneNumber: string, currentPhoneNumber: string | undefined) {
    return !isEqual(originalPhoneNumber, currentPhoneNumber) && !StringUtil.isEmpty(currentPhoneNumber);
  }
}
